import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

const isDebug = process.env.REACT_APP_MIXPANEL_DEBUG

const useMixPanel = () => {
  useEffect(() => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN

    if (token) {
      mixpanel.init(token, {
        debug: isDebug === 'true' ? true : false,
        track_pageview: false,
        persistence: 'localStorage'
      })
    } else {
      console.error('Mixpanel token is missing.')
    }
  }, [])

  const trackEvent = (eventName, properties = {}) => {
    if (isDebug === 'true') return //Remove this comment to make test on locahost and/or dev
    mixpanel.track(eventName, properties)
  }

  const setUser = (userId, properties = {}) => {
    mixpanel.identify(userId)
    mixpanel.people.set(properties)
  }

  return { trackEvent, setUser }
}

export default useMixPanel
